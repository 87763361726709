<template>
    <b-dropdown-item
        class="hapus-dokter elipsis-item"
        @click.prevent="deleteEmit"
    >
        <div style="width: 22px;">
            <TrashIcon :strokeColor="'#C2283C'" />
        </div>
        <p>
            Hapus Subspesialisasi
        </p>
    </b-dropdown-item>
</template>

<script>
import TrashIcon from "../../_icons/TrashIcon.vue";
export default {
    components: {
        TrashIcon
    },
    methods: {
        deleteEmit() {
            this.$emit("delete-subspesialisasi");
        }
    }
};
</script>

<style></style>
