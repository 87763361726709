export default class SubspesialisasiClass {
    static dataSubspesialisasi(data) {
        if (data) {
            if (Array.isArray(data)) {
                return data.map(item => {
                    return {
                        id: item.ID,
                        namaIndonesia: item.namaIndo,
                        namaInggris: item.namaEng,
                        namaAwam: item.namaAwam,
                        namaAwamInggris: item.namaAwamEng,
                        status: {
                            text: item.isPublish ? "published" : "unpublished",
                            isActive: item.isPublish
                        }
                        // spesialisasis: item.spesialisasis
                    };
                });
            }
        }
    }
    static dataPostSubspesialisasi(data) {
        if (data) {
            if (typeof data == "object") {
                return {
                    namaIndo: data.namaIndonesia,
                    namaEng: data.namaInggris,
                    namaAwam: data.namaAwamIndonesia,
                    namaAwamEng: data.namaAwamInggris,
                    isPublish: data.isPublish
                };
            }
        }
    }
    static dataPutSubspesialisasi(data) {
        if (data) {
            if (typeof data == "object") {
                return {
                    ID: data.id,
                    namaIndo: data.namaIndonesia,
                    namaEng: data.namaInggris,
                    namaAwam: data.namaAwamIndonesia,
                    namaAwamEng: data.namaAwamInggris,
                    isPublish: data.isPublish,
                    spesialisasis: data.spesialisasis
                };
            }
        }
    }
}
