<template>
    <Modal class="custom-modal doctor-filter">
        <ModalHeader class="custom-header"
            ><h3>{{ modalTitle }}</h3>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="dismiss(idComponent)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </ModalHeader>
        <ModalBody>
            <form class="form-modal">
                <div
                    v-if="isViewOnly || modalTitle == 'Edit Subspesialisasi'"
                    class="form-group"
                >
                    <label class=" col-form-label form-label">ID</label>
                    <div>
                        <span style="margin-left: 12px;">{{
                            subspesialisasiData.id | strip
                        }}</span>
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Nama ID<span style="color:red;">*</span></label
                    >
                    <div v-if="!isViewOnly">
                        <input
                            type="text"
                            class="form-control"
                            v-model="subspesialisasiData.namaIndonesia"
                            placeholder="Tulis Nama Indonesia"
                            v-validate="'required'"
                            data-vv-as="Nama Indonesia"
                            name="Indonesia"
                            :class="{ 'is-invalid': errors.has('Indonesia') }"
                        />
                        <div
                            v-show="errors.first('Indonesia')"
                            class="invalid-feedback"
                        >
                            {{ errors.first("Indonesia") }}
                        </div>
                    </div>
                    <div v-else>
                        <span style="margin-left: 12px;">{{
                            subspesialisasiData.namaIndonesia | strip
                        }}</span>
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Nama EN<span style="color:red;">*</span></label
                    >
                    <div v-if="!isViewOnly">
                        <input
                            type="text"
                            class="form-control"
                            v-model="subspesialisasiData.namaInggris"
                            placeholder="Tulis Nama Inggris"
                            v-validate="'required'"
                            data-vv-as="Nama Inggris"
                            name="Inggris"
                            :class="{ 'is-invalid': errors.has('Inggris') }"
                        />
                        <div
                            v-show="errors.first('Inggris')"
                            class="invalid-feedback"
                        >
                            {{ errors.first("Inggris") }}
                        </div>
                    </div>
                    <div v-else>
                        <span style="margin-left: 12px;">{{
                            subspesialisasiData.namaInggris | strip
                        }}</span>
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Nama Awam ID</label
                    >
                    <div v-if="!isViewOnly">
                        <input
                            type="text"
                            class="form-control"
                            v-model="subspesialisasiData.namaAwamIndonesia"
                            placeholder="Tulis Nama Awam Indonesia"
                        />
                    </div>
                    <div v-else>
                        <span style="margin-left: 12px;">{{
                            subspesialisasiData.namaAwamIndonesia | strip
                        }}</span>
                    </div>
                </div>

                <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Nama Awam EN</label
                    >
                    <div v-if="!isViewOnly">
                        <input
                            type="text"
                            class="form-control"
                            v-model="subspesialisasiData.namaAwamInggris"
                            placeholder="Tulis Nama Awam Inggris"
                        />
                    </div>
                    <div v-else>
                        <span style="margin-left: 12px;">{{
                            subspesialisasiData.namaAwamInggris | strip
                        }}</span>
                    </div>
                </div>
            </form>
        </ModalBody>

        <ModalFooter class="custom-footer footer-doctor">
            <div v-if="!isViewOnly">
                <span>
                    <EyeFillIcon
                        style="width: 19px;"
                        v-if="subspesialisasiData.isPublish"
                    />
                    <EyeIconSlashed style="width: 19px;" v-else />Publish
                    Subspesialisasi
                </span>
                <label style="margin-top: 7px;" class="switch-publish">
                    <input
                        v-model="subspesialisasiData.isPublish"
                        type="checkbox"
                    />
                    <span class="slider round"></span>
                </label>
            </div>
            <div v-else>
                <PublishChips
                    :isPublished="subspesialisasiData.isPublish"
                    :textString="
                        subspesialisasiData.isPublish
                            ? 'published'
                            : 'unpublished'
                    "
                />
            </div>
            <div v-if="!isViewOnly" class="d-flex">
                <a
                    class="link-batal"
                    style="margin-right: 40px;line-height: 40px;"
                    @click.prevent="dismiss(idComponent)"
                    >Batal</a
                >
                <button
                    @click.prevent="testValidate"
                    class="btn btn-primary custom-modal-button"
                >
                    Simpan
                </button>
            </div>
            <div v-else>
                <button
                    @click.prevent="editModal"
                    class="btn btn-primary custom-modal-button"
                >
                    Edit
                </button>
            </div>
        </ModalFooter>
    </Modal>
</template>

<script>
import Modal from "../../../_modal/Modal.vue";
import ModalHeader from "../../../_modal/ModalHeader.vue";
import ModalBody from "../../../_modal/ModalBody.vue";
import ModalFooter from "../../../_modal/ModalFooter.vue";
import ModalMixin from "../../../../mixins/ModalMixins";

// import FileInput from "../../../_base/FileInputCustom.vue";

import VueInputText from "../../../_general/VueInputText.vue";

import EyeIconSlashed from "../../../_icons/EyeIconSlashed.vue";
import EyeFillIcon from "../../../_icons/EyeFillIcon.vue";
import PublishChips from "../../../_base/PublishChips.vue";

import VeeValidate from "vee-validate";
VeeValidate.setMode("passive");

// import Multiselect from "vue-multiselect";
export default {
    name: "AddSubspesialisasiModal",
    components: {
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter,
        PublishChips,
        // Multiselect,
        // FileInput,
        EyeIconSlashed,
        EyeFillIcon,
        VueInputText
    },
    mixins: [ModalMixin],
    provide() {
        return {
            $validator: this.$validator
        };
    },
    data() {
        return {
            subspesialisasiData: {
                id: null,
                namaIndonesia: null,
                namaAwamIndonesia: null,
                namaAwamInggris: null,
                namaInggris: null,
                isPublish: true,
                spesialisasis: null
            },
            isViewOnly: false,
            modalTitle: ""
        };
    },
    methods: {
        testValidate() {
            this.$validator.validateAll().then(result => {
                console.log(result);
                if (result) {
                    this.sendData(this.subspesialisasiData);
                }
                // if (result) {
                //   if (this.tarif.id) {
                //     this.tarif.jumlah = Number(this.tarif.jumlah)
                //     this.updateById({ repository: TarifRepository.updateTarif, data: this.tarif });
                //   } else {
                //     this.createNew({ repository: TarifRepository.createTarif, data: this.tarif });
                //   }
                //   return;
                // }
            });
        },
        sendData(data) {
            if (this.componentData) {
                if (typeof this.componentData.action == "function") {
                    this.componentData.action(data);
                }
            }
        },
        editModal() {
            this.isViewOnly = false;
            this.modalTitle = "Edit Subspesialisasi";
        }
    },
    // props: {
    // modalTitle: {
    //     type: String,
    //     default: "Tambah Subspesialisasi"
    // }
    // },
    filters: {
        strip(text) {
            return text ? text : " - ";
        }
    },
    created() {
        console.log(this.componentData, "componentDataa");

        if (this.componentData) {
            if (this.componentData.initialData) {
                this.subspesialisasiData.id = this.componentData.initialData.ID;
                this.subspesialisasiData.namaIndonesia = this.componentData.initialData.namaIndo;
                this.subspesialisasiData.namaAwamIndonesia = this.componentData.initialData.namaAwam;
                this.subspesialisasiData.namaAwamInggris = this.componentData.initialData.namaAwamEng;
                this.subspesialisasiData.namaInggris = this.componentData.initialData.namaEng;
                this.subspesialisasiData.isPublish = this.componentData.initialData.isPublish;
                this.subspesialisasiData.spesialisasis = this.componentData.initialData.spesialisasis;
            }
            if (this.componentData.isViewOnly) {
                this.isViewOnly = this.componentData.isViewOnly;
            }
            if (this.componentData.modalTitle) {
                this.modalTitle = this.componentData.modalTitle;
            }
        }
    }
};
</script>

<style></style>
