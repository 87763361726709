export default class PermissionClass {
    static isRenderByPermission(arrayPermission) {
        let permissionObject = {
            isCanCreateSubspesialisasi: false,
            isCanEditSubspesialisasi: false,
            isCanDeleteSubspesialisasi: false,
            isCanViewDetailSubspesialisasi: false
        };
        let permissionName = {
            isCanCreateSubspesialisasi:
                "DoctorResource.POST.CreateSubspesialisasi",
            isCanEditSubspesialisasi:
                "DoctorResource.PUT.UpdateSubspesialisasi",
            isCanDeleteSubspesialisasi:
                "DoctorResource.DEL.DeleteSubspesialisasi",
            isCanViewDetailSubspesialisasi:
                "DoctorResource.View.SubspesialisasiDetail"
        };

        if (arrayPermission) {
            if (Array.isArray(arrayPermission)) {
                Object.keys(permissionName).forEach(key => {
                    if (arrayPermission.includes(permissionName[key])) {
                        permissionObject[key] = true;
                    }
                });
            }
        }

        return permissionObject;
    }
}
