<template>
    <div>
        <base-header
            class="sticky-base-header"
            base-title="Master Klinik"
        ></base-header>
        <SubspesialisasiScreen
            :methodObject="methodObject"
            :buttonMethod="buttonMethod"
            :filterList="filterList"
            :isFetching="isFetching"
            :permissionObject="permissionObject"
        >
        </SubspesialisasiScreen>
    </div>
</template>

<script>
import { Event } from "vue-tables-2";
import BaseHeader from "../../_base/BaseHeader.vue";

import ModalService from "../../../services/modal.service";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const subSpesialisasiRepo = RepositoryFactory.get("subSpe");
import createRequestOption from "../../../repositories/RequestParamUtil";
import BlockUI from "../../_js/BlockUI";

import SubspesialisasiFilterModal from "../component/subspesialisasiModal/subspesialisasiFilterModal.vue";
import AddSubspesialisasiModal from "../component/subspesialisasiModal/AddSubspesialisasiModal.vue";
// import KlinikFilterModal from "../component/KlinikModal/KlinikModalFilter.vue";
// import KlinikModal from "../component/KlinikModal/KlinikModal.vue";

import SubspesialisasiScreen from "./SubspesialisasiScreen.vue";

import DataClass from "../class/subspesialisasi-data-class";
import PermissionClass from "../class/permission-class";

// import DangerImg from "../../_icons/DangerImg.png";

import SwalCustom from "../../_general/SwalCustom.vue";
const SweetAlert = Vue.extend(SwalCustom);

export default {
    name: "SubspesialisasiController",
    components: {
        SubspesialisasiScreen,
        BaseHeader
    },
    data() {
        var vx = this;
        return {
            blockUi: new BlockUI(),
            // dangerImg: DangerImg,
            filterList: {},
            methodObject: {
                controllerReqFunctionMethod: vx.controllerReqFunction,
                controllerRespFunctionMethod: vx.controllerRespFunction,
                openModalFilterMethod: vx.openModalFilter,
                subspesialisasiAddModalMethod: vx.subspesialisasiAddModal,
                getDataTable: vx.emitFilterQuery,
                filterItemsDataMethod: vx.filterItemsData,
                deleteIndvFIlterMethod: vx.deleteIndvFIlter,
                deleteAllFilterMethod: vx.deleteAllFilter
            },
            buttonMethod: {
                deleteConfirmationSubspesialisasiMethod:
                    vx.deleteConfirmationSubspesialisasi,
                subspesialisasiEditModalMethod: vx.subspesialisasiEditModal
            },
            isFetching: false,
            permissionObject: {}
        };
    },
    watch: {
        filterList: {
            handler(val, oldVal) {
                this.$router.push(
                    {
                        name: "master-subspesialisasi",
                        query: this.filterList
                    },
                    e => {
                        this.routeQueryToFilterList();
                        // this.emitFilterQuery();
                    }
                );
            },
            deep: true
        }
    },
    methods: {
        initPermissionCheck() {
            let arrayPermission = localStorage.getItem("permission").split(",");
            this.permissionObject = PermissionClass.isRenderByPermission(
                arrayPermission
            );
        },
        deleteAllFilter() {
            this.filterList = {};
            this.emitFilterQuery();
        },
        showPopUp(message, title, icon) {
            let messageStr = message ? message : "";
            let titleStr = title ? title : "";
            let iconStr = icon ? icon : "";
            new SweetAlert().show(
                {
                    title: titleStr,
                    // html: html,
                    text: messageStr,
                    allowOutsideClick: true,
                    // reverseButtons: true,
                    // showCancelButton: true,
                    textConfirm: "Ok",
                    timer: 3000
                },
                iconStr
            );
        },
        showErrorPopUp(err) {
            let message = "Unknown Error Has Occurred";
            let title = "Error";

            if (err.message) {
                message = err.message;
            }
            if (err.error) {
                title = err.error;
            }
            new SweetAlert().show(
                {
                    title: title,
                    text: message,
                    allowOutsideClick: true,
                    reverseButtons: true,
                    showCancelButton: false,
                    textConfirm: "OK"
                },
                "dangerImg"
            );
        },
        deleteIndvFIlter(payload) {
            let { category, value } = payload;

            const asArray = Object.entries(this.filterList);

            const filtered = asArray.filter(([key, v]) => {
                return v != value.replace(key, "");
            });

            const newFIlterList = Object.fromEntries(filtered);
            this.filterList = newFIlterList;
            this.emitFilterQuery();
            // const unblock = this.blockUi.blockPage();
            // this.getDoctorList();
            // unblock();
        },
        filterItemsData() {
            let payload = [];
            for (let key in this.filterList) {
                if (this.filterList[key] != null) {
                    payload.push({
                        category: key,
                        value: this.filterList[key]
                    });
                }
            }
            console.log(payload, "filter item list data");
            return payload;
        },
        controllerReqFunction(query) {
            let data = query;
            // throw new Error("Method not implemented.");
            console.log(data, "controllerReqFunction");
            let params = {};
            params.page = data.page;
            params.page_size = data.limit;
            if (!_.isEmpty(this.filterList)) {
                data.filterQuery = this.filterList;
            }
            if (!data.filterQuery && !data.orderBy) {
                this.isFetching = true;
                return subSpesialisasiRepo
                    .getSubpspesialisasi({ params })
                    .catch(async err => {
                        this.isFetching = false;
                        console.log(err);
                        this.showErrorPopUp(err);
                    });
            } else {
                if (data.filterQuery.dateRange) {
                    params.holidayStart = data.filterQuery.dateRange.start;
                    params.holidayEnd = data.filterQuery.dateRange.end;
                }
                // if(data.filterQuery.holidayType){
                //     params.holidayType = data.filterQuery.holidayType
                // }
                // if (condition) {

                // }
                let queryNames = {
                    id: "id",
                    namaAwam: "namaAwam",
                    namaAwamEng: "namaAwamEng",
                    namaEng: "namaEng",
                    namaIndo: "namaIndo"
                };
                let sortNames = {
                    namaIndonesia: "sort"
                };
                let requestParam = createRequestOption(params);
                if (data.orderBy) {
                    let order = data.ascending ? "asc" : "desc";
                    requestParam.params.append(sortNames[data.orderBy], order);
                }

                Object.keys(data.filterQuery).forEach(query => {
                    if (query != "isPublish") {
                        if (data.filterQuery[query]) {
                            requestParam.params.append(
                                queryNames[query],
                                data.filterQuery[query]
                            );
                        }
                    } else {
                        if (data.filterQuery[query] == "unpublish") {
                            requestParam.params.append("isPublish", false);
                        } else {
                            requestParam.params.append("isPublish", true);
                        }
                    }
                });
                // console.log(requestParam.params.toString(), "requestParam");
                this.isFetching = true;
                return subSpesialisasiRepo
                    .getSubpspesialisasi(requestParam)
                    .catch(async err => {
                        this.isFetching = false;
                        this.showErrorPopUp(err);
                        console.log(err, "controllerReqFunction error");
                        console.log(
                            err.message,
                            "controllerReqFunction error 119"
                        );
                    });
            }
        },
        controllerRespFunction(resp) {
            let classData = DataClass.dataSubspesialisasi(resp.data);
            console.log(classData, "classData");
            // console.log(resp.config.params, "133 response");
            this.isFetching = false;
            return {
                data: classData,
                count: resp.headers["x-count"]
                // resp.headers["X-Count"]
            };
        },
        openModalFilter() {
            let closeModalFunc;
            let payload = {
                initialData: this.filterList,
                action: data => {
                    const validated = this.validateFilterData(data);
                    this.filterList = validated;
                    this.emitFilterQuery();
                    closeModalFunc();
                }
            };
            let { close } = ModalService.open(
                SubspesialisasiFilterModal,
                payload
            );
            closeModalFunc = close;
        },
        subspesialisasiAddModal() {
            let closeModalFunc;
            let payload = {
                modalTitle: "Tambah Subspesialisasi",
                action: async data => {
                    let unblock;
                    try {
                        const payload = this.validateData(data);
                        console.log(payload);
                        if (payload) {
                            const result = await this.dataConfirmation(
                                payload,
                                "Subspesialisasi akan ditambah",
                                "Apakah Anda yakin ingin menambah Subspesialisasi berikut?"
                            );
                            if (result.value) {
                                unblock = this.blockUi.blockModal();
                                const resp = await subSpesialisasiRepo.postSubpspesialisasi(
                                    payload
                                );
                                unblock();
                                if (!resp.error) {
                                    this.showPopUp(
                                        "Data Subspesialisasi berhasil ditambah",
                                        "success",
                                        "successImg"
                                    );
                                    this.emitFilterQuery();
                                    closeModalFunc();
                                } else {
                                    throw resp;
                                }
                            }
                        }
                    } catch (error) {
                        if (typeof unblock == "function") {
                            unblock();
                        }
                        console.log(error, "error");
                        this.showErrorPopUp(error);
                    }
                }
            };
            let { close } = ModalService.open(AddSubspesialisasiModal, payload);
            closeModalFunc = close;
        },
        async subspesialisasiEditModal(props, isViewOnly) {
            let unblock;
            try {
                let closeModalFunc;
                unblock = this.blockUi.blockPage();
                const initialData = await subSpesialisasiRepo.getOneSubpspesialisasi(
                    props.id
                );
                unblock();
                if (initialData.error) {
                    throw initialData;
                }
                let modalTitle = "Edit Subspesialisasi";
                if (isViewOnly) {
                    modalTitle = "Detail Subspesialisasi";
                }
                let payload = {
                    modalTitle,
                    initialData: initialData,
                    isViewOnly: isViewOnly,
                    action: async data => {
                        let unblock;
                        try {
                            unblock = this.blockUi.blockModal();
                            const payload = DataClass.dataPutSubspesialisasi(
                                data
                            );
                            const resp = await subSpesialisasiRepo.putSubpspesialisasi(
                                payload
                            );

                            // const payload = this.validateData(data);
                            // console.log(payload);
                            // if (payload) {
                            // unblock = this.blockUi.blockModal();
                            // const resp = await subSpesialisasiRepo.putSubpspesialisasi(
                            //     payload
                            // );
                            unblock();
                            if (!resp.error) {
                                this.emitFilterQuery();
                                closeModalFunc();
                                this.showPopUp(
                                    "Data Subspesialisasi berhasil diperbarui",
                                    "success",
                                    "successImg"
                                );
                                this.emitFilterQuery();
                            } else {
                                throw resp;
                            }
                            // }
                        } catch (error) {
                            if (typeof unblock == "function") {
                                unblock();
                            }
                            console.log(error, "error");
                            this.showErrorPopUp(error);
                        }
                    }
                };
                let { close } = ModalService.open(
                    AddSubspesialisasiModal,
                    payload
                );
                closeModalFunc = close;
            } catch (error) {
                if (typeof unblock == "function") {
                    unblock();
                }
                console.log(error, "subspesialisasiEditModal error");
                this.showErrorPopUp(error);
            }
        },
        validateData(data) {
            const payload = DataClass.dataPostSubspesialisasi(data);
            return payload;
        },
        async dataConfirmation({ id, namaIndo }, text, title) {
            try {
                let html = `<div class="sub-delete">`;
                html += `<div class="data-warning"  style="background: #F3F8FF;">
                        <p>${namaIndo}</p>
                        <p> ${id ? `ID:${id}` : ``} </p>
                     </div>`;
                html += `<p class="sub-delete__text">${text}</p>`;
                html += `</div>`;
                const result = await new SweetAlert().show(
                    {
                        title: title,
                        html: html,
                        // text: "Data tarif akan terhapus secara permanen",
                        allowOutsideClick: false,
                        reverseButtons: true,
                        showCancelButton: true,
                        textConfirm: "Ya, Tambah",
                        textCancel: "Tidak, Batalkan"
                    },
                    "warningImg"
                );
                return result;
            } catch (error) {
                //    if (typeof unblock == "function") {
                //         unblock();
                //     }
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        validateFilterData(data) {
            let payload = { ...data };
            Object.keys(payload).forEach(key => {
                if (!payload[key]) {
                    delete payload[key];
                }
                // if (key == "dateRange") {
                //     if (!payload.dateRange.start || !payload.dateRange.end) {
                //         delete payload.dateRange;
                //     }
                // } else if (payload[key].length <= 0) {
                //     delete payload[key];
                // }
            });
            return payload;
        },
        confirmationDataValidate(data) {
            let namaDokters = [];
            let namaSpesialisasi = [];
            if (data) {
                if (data.dokterSubSpesialisasis) {
                    data.dokterSubSpesialisasis.forEach(element => {
                        namaDokters.push(element.dokter.nama);
                    });
                }
                if (data.spesialisasis) {
                    data.spesialisasis.forEach(element => {
                        namaSpesialisasi.push(element.namaIndo);
                    });
                }
            }
            return {
                namaDokters,
                namaSpesialisasi
            };
        },
        async deleteConfirm({ id, namaIndonesia }) {
            try {
                let html = `<div class="sub-delete">`;
                html += `<div class="data-warning">
                        <p>${namaIndonesia}</p>
                        <p> ID: ${id} </p>
                     </div>`;
                html += `</div>`;
                const result = await new SweetAlert().show(
                    {
                        title:
                            "Apakah Anda yakin ingin menghapus subspesialisasi berikut?",
                        html: html,
                        allowOutsideClick: false,
                        reverseButtons: true,
                        showCancelButton: true,
                        textConfirm: "Ya, hapus data",
                        textCancel: "Tidak, batalkan"
                    },
                    "dangerImg"
                );
                return result;
            } catch (error) {
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async deleteConfirmationSubspesialisasi({ id, namaIndonesia }) {
            let unblock;
            try {
                const confirmation = await this.deleteConfirm({
                    id,
                    namaIndonesia
                });
                if (confirmation.value) {
                    unblock = this.blockUi.blockPage();
                    const resp = await subSpesialisasiRepo.delConfirmationDelete(
                        id
                    );
                    unblock();
                    if (resp) {
                        if (resp.error) {
                            throw resp;
                        }

                        const {
                            namaDokters,
                            namaSpesialisasi
                        } = this.confirmationDataValidate(resp);

                        let html = `<div class="sub-delete">`;
                        html += `<div class="data-warning">
                            <p>${namaIndonesia}</p>
                            <p> ID: ${id} </p>
                         </div>`;
                        if (namaDokters.length > 0) {
                            html += `<div class="swal-dokter-container"> <p>Dokter terkait:</p> <div> ${namaDokters
                                .map(el => {
                                    return `<p>${el}</p>`;
                                })
                                .join("")}
                                 </div> </div>`;
                        }
                        if (namaSpesialisasi.length > 0) {
                            html += `<div class="swal-dokter-container"> <p>Spesialisasi terkait::</p> <div> ${namaSpesialisasi
                                .map(el => {
                                    return `<p>${el}</p>`;
                                })
                                .join("")}
                        </div> </div>`;
                        }
                        html += `</div>`;
                        const result = await new SweetAlert().show(
                            {
                                title:
                                    "Apakah Anda yakin ingin menghapus subspesialisasi berikut?",
                                html: html,
                                allowOutsideClick: false,
                                reverseButtons: true,
                                showCancelButton: true,
                                textConfirm: "Ya, hapus data",
                                textCancel: "Tidak, batalkan"
                            },
                            "dangerImg"
                        );
                        if (result.value) {
                            this.deleteSubspesialisasi(resp);
                        }
                    } else {
                        this.showPopUp(
                            "Data Subspesialisasi berhasil dihapus",
                            "success",
                            "successImg"
                        );
                        this.emitFilterQuery();
                    }
                }
            } catch (error) {
                if (typeof unblock == "function") {
                    unblock();
                }
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async deleteSubspesialisasi(id) {
            let unblock;
            try {
                unblock = this.blockUi.blockPage();
                const resp = await subSpesialisasiRepo.delSubspesialisasi(id);
                unblock();
                this.showPopUp(
                    "Data Subspesialisasi berhasil dihapus",
                    "success",
                    "successImg"
                );
                this.emitFilterQuery();
            } catch (error) {
                if (typeof unblock == "function") {
                    unblock();
                }
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        emitFilterQuery() {
            Event.$emit(
                "vue-tables.tarif_table.filter::filterQuery",
                this.filterList
            );
        },
        routeQueryToFilterList() {
            let filterQueryNames = [
                "id"
                // "isPublish",
                // "namaIndo",
                // "namaAwam",
                // "namaAwamEng",
                // "namaEng"
            ];
            filterQueryNames.forEach(name => {
                if (this.$route.query[name]) {
                    this.filterList[name] = this.$route.query[name];
                }
            });
        }
    },
    created() {
        this.initPermissionCheck();
        this.routeQueryToFilterList();
    },
    mounted() {
        // isPublish: null,
        //         id: "",
        //         namaIndo: "",
        //         namaAwam: "",
        //         namaAwamEng: "",
        //         namaEng: ""
        // if (!_.isEmpty(this.$route.query)) {
        //     this.emitFilterQuery();
        // }
        // if (this.$route.query.id) {
        //     this.filterList.id = this.$route.query.id;
        // }
    }
};
</script>

<style></style>
