<template>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="kt-portlet kt-portlet--mobile head-container-kt">
            <div
                style="border-bottom: unset;padding: 20px 20px 0 20px;"
                class="kt-portlet__head kt-portlet__head--lg header-container"
            >
                <base-title
                    style="width: 100%;"
                    class="head-label"
                    :head-title="headTitle"
                ></base-title>
                <div
                    class="kt-portlet__head-toolbar"
                    style="width: 100%;justify-content: flex-end;"
                >
                    <button
                        :disabled="isFetching"
                        @click.prevent="getData"
                        type="button"
                        class="refresh-button"
                    >
                        <div>
                            <RefreshIcon
                                style="width:14px;"
                                :strokeColor="
                                    isFetching ? '#c1c1c1' : '#23415B'
                                "
                            ></RefreshIcon>
                        </div>
                    </button>
                    <button
                        v-if="permissionObject.isCanCreateSubspesialisasi"
                        @click.prevent="subspesialisasiAddModal"
                        type="button"
                        class="btn btn-primary custom-modal-button"
                    >
                        <p>Tambah Subspesialisasi</p>
                        <i class="flaticon2-add-1"></i>
                    </button>
                </div>
            </div>
            <div class="kt-portlet__body">
                <div
                    class="row"
                    style="margin-bottom: 2em;flex-direction: row-reverse;"
                >
                    <!-- <div class="col-md-5 offset-md-3">
                        <SearchInput style="width:unset;display:none;" />
                    </div> -->
                    <div>
                        <FilterButton
                            :activeFilter="activeFilter()"
                            :clickedMethod="openModalFilter"
                        >
                        </FilterButton>
                    </div>
                </div>
                <div
                    v-if="activeFilter()"
                    style="padding: 0 20px 0 20px;"
                    class="selected-filter"
                >
                    <FilterItems
                        :isFetching="isFetching"
                        :clickedMethod="deleteIndvFilter"
                        :filterList="filterItemData()"
                    ></FilterItems>

                    <a
                        v-if="!isFetching"
                        @click.prevent="deleteAllFilter"
                        style="cursor:pointer;"
                    >
                        Hapus Semua Filter</a
                    >
                </div>
                <div
                    class="record-limit"
                    style="display: flex;flex-direction: row-reverse;align-items: center;margin-bottom: 15px;"
                >
                    <select
                        @change="$refs.table.setLimit($event.target.value)"
                        name="limit"
                    >
                        <option
                            v-for="(value, index) in recordValues"
                            :key="index"
                            :value="value"
                            >{{ value }}</option
                        >
                    </select>
                    <label>Show Records :</label>
                </div>
                <div class="table-container">
                    <h3>Daftar Subspesialisasi</h3>
                    <v-server-table
                        id="holiday-table"
                        :columns="columns"
                        :options="options"
                        name="tarif_table"
                        ref="table"
                    >
                        <PublishChips
                            class="publish-table"
                            slot="status"
                            slot-scope="{ row }"
                            :isPublished="row.status.isActive"
                            :textString="row.status.text"
                        >
                        </PublishChips>
                        <BtnAction
                            v-if="
                                permissionObject.isCanEditSubspesialisasi ||
                                    permissionObject.isCanDeleteSubspesialisasi ||
                                    permissionObject.isCanViewDetailSubspesialisasi
                            "
                            slot-scope="{ row }"
                            :rowData="row"
                            slot="action"
                            :buttonMethod="buttonMethod"
                            :permissionObject="permissionObject"
                        >
                            <OptionEdit
                                v-if="permissionObject.isCanEditSubspesialisasi"
                                slot="dropdown-item"
                                @edit-subspesialisasi="editMethod(row)"
                            >
                            </OptionEdit>
                            <OptionDelete
                                v-if="
                                    permissionObject.isCanDeleteSubspesialisasi
                                "
                                slot="dropdown-item"
                                @delete-subspesialisasi="deleteMethod(row)"
                            >
                            </OptionDelete>
                        </BtnAction>
                    </v-server-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTitle from "../../_base/BaseTitle.vue";

import BtnAction from "../component/BtnAction.vue";

import FilterButton from "../../_base/FilterButton.vue";
import FilterItems from "../../_base/FilterItems.vue";
import SearchInput from "../../_base/SearchInput.vue";

import PublishChips from "../../_base/PublishChips.vue";
import RefreshIcon from "../../_icons/RefreshIcon.vue";

import OptionDelete from "../component/OptionDeleteButton.vue";
import OptionEdit from "../component/OptionEditButton.vue";

export default {
    name: "SubspesialisasiScreen",
    components: {
        BaseTitle,
        FilterButton,
        FilterItems,
        SearchInput,
        PublishChips,
        BtnAction,
        RefreshIcon,
        OptionDelete,
        OptionEdit
    },
    props: {
        methodObject: { type: Object },
        buttonMethod: { type: Object },
        filterList: { type: Object },
        isFetching: { type: Boolean },
        permissionObject: { type: Object }
    },
    methods: {
        filterItemData() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.filterItemsDataMethod == "function"
                ) {
                    return this.methodObject.filterItemsDataMethod();
                }
            }
        },
        deleteIndvFilter(payload) {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.deleteIndvFIlterMethod ==
                    "function"
                ) {
                    this.methodObject.deleteIndvFIlterMethod(payload);
                }
            }
        },
        deleteAllFilter() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.deleteAllFilterMethod == "function"
                ) {
                    this.methodObject.deleteAllFilterMethod();
                }
            }
        },
        activeFilter() {
            let activeNumber = 0;
            if (this.filterList) {
                Object.keys(this.filterList).forEach(key => {
                    // if (key == "dateRange") {
                    //     if (this.filterList.dateRange) {
                    //         if (this.filterList.dateRange.start) {
                    //             activeNumber++;
                    //         }
                    //         if (this.filterList.dateRange.end) {
                    //             activeNumber++;
                    //         }
                    //     }
                    // } else if (
                    //     key == "tipeTatapMuka" ||
                    //     key == "tipeTelekonsul"
                    // ) {
                    //     if (
                    //         this.filterList[key] !== null ||
                    //         this.filterList[key] !== undefined
                    //     ) {
                    //         activeNumber++;
                    //     }
                    // } else {
                    if (
                        this.filterList[key].length > 0 &&
                        typeof this.filterList[key] !== "string"
                    ) {
                        activeNumber += this.filterList[key].length;
                    } else if (typeof this.filterList[key] == "string") {
                        activeNumber++;
                    }
                    // }
                });
                return activeNumber;
            }
        },
        serverTableInit() {
            if (this.methodObject) {
                if (this.methodObject.controllerReqFunctionMethod) {
                    this.options.requestFunction = this.methodObject.controllerReqFunctionMethod;
                }
                if (this.methodObject.controllerRespFunctionMethod) {
                    this.options.responseAdapter = this.methodObject.controllerRespFunctionMethod;
                }
            }
        },
        openModalFilter() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.openModalFilterMethod == "function"
                ) {
                    this.methodObject.openModalFilterMethod();
                }
            }
        },
        subspesialisasiAddModal() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.subspesialisasiAddModalMethod ==
                    "function"
                ) {
                    this.methodObject.subspesialisasiAddModalMethod();
                }
            }
        },
        deleteMethod(id) {
            console.log(id, "deleteMethod");
            if (this.buttonMethod) {
                if (
                    typeof this.buttonMethod
                        .deleteConfirmationSubspesialisasiMethod == "function"
                ) {
                    this.buttonMethod.deleteConfirmationSubspesialisasiMethod(
                        id
                    );
                }
            }
        },
        editMethod(data) {
            if (this.buttonMethod) {
                {
                    if (
                        typeof this.buttonMethod
                            .subspesialisasiEditModalMethod == "function"
                    ) {
                        this.buttonMethod.subspesialisasiEditModalMethod(data);
                    }
                }
            }
        },
        getData() {
            if (this.methodObject) {
                if (typeof this.methodObject.getDataTable == "function") {
                    this.methodObject.getDataTable();
                }
            }
        }
    },
    data() {
        return {
            headTitle: "Master Subspesialisasi",
            recordValues: [10, 25, 50, 100],
            columns: [
                "id",
                "namaIndonesia",
                "namaInggris",
                "namaAwam",
                "namaAwamInggris",
                "status",
                "action"
            ],
            options: {
                texts: {
                    filterBy: "Cari",
                    loadingError: "testing"
                },
                filterable: false,
                headings: {
                    id: "ID",
                    namaIndonesia: "Nama ID",
                    namaInggris: "Nama EN",
                    namaAwam: "Nama Awam ID",
                    namaAwamInggris: "Nama Awam EN",
                    status: "Status",
                    action: "Actions"
                },
                sortIcon: {
                    base: "fas",
                    is: "fa-sort",
                    up: "fa-sort-up",
                    down: "fa-sort-down"
                },
                sortable: [
                    // "id",
                    "namaIndonesia"
                    // "namaInggris",
                    // "namaAwam",
                    // "namaAwamInggris"
                ],
                perPageValues: [],
                toMomentFormat: "YYYY-MM-DD",
                filterByColumn: false,
                requestFunction: null,
                responseAdapter: null,
                customFilters: ["filterQuery"]
                // templates: {
                //     jumlah(h, { jumlah }) {
                //         // return jumlah with indonesian currency format
                //         return `Rp. ${jumlah.toLocaleString("id-ID")}`;
                //     }
                // }
            }
        };
    },
    created() {
        this.serverTableInit();
    }
};
</script>

<style></style>
